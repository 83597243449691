import {
  BillOfLadingCarrierInfo,
  BillOfLadingFreightItem,
  BillOfLadingFreightPieceType,
  BillOfLadingFreightType,
} from '@mothership/document-types'
import { startCase } from 'lodash'
import { formatPhoneNumberToNational } from '../../utils/string'
import { Column, Row } from '../Pane'
import { Text } from '../Text'
import { BOL_DEFAULT_BORDER, BOL_DEFAULT_HEADER_PADDING, BOL_DEFAULT_MARGIN } from './BillOfLadingDefaults'

const CARGO_COLUMN_WIDTH = [52, 39, 0, 33, 37]
// NOTE [jdao]: Ideally we don't import axel-types to get the IntegratedCarrier here
// Since axel-types adds some unnecessary dependencies to the project.
// In the future, these values should be gotten from @mothership/order.
const CARRIERS_USING_HAZMAT_PACKAGING_DESCRIPTION = new Set([
  'ABF Freight',
  'Roadrunner Transportation Service',
  'Southeastern Freight Lines',
  'Ward Transport and Logistics',
])

interface BillOfLadingFreightProps {
  freight: Array<BillOfLadingFreightItem>
  carrierInfo: BillOfLadingCarrierInfo | null | undefined
}

const _quantityTypeDescription = (item: BillOfLadingFreightItem, carrierName: string | undefined): string => {
  if (
    carrierName &&
    CARRIERS_USING_HAZMAT_PACKAGING_DESCRIPTION.has(carrierName) &&
    item.isHazmat &&
    item.hazmatPackageCount &&
    item.hazmatPackageType
  ) {
    return `${item.hazmatPackageCount} ${startCase(item.hazmatPackageType.toLowerCase())}`
  }

  const type: BillOfLadingFreightType = item.type
  const quantity: number = item.quantity

  const pluralize = quantity > 1 ? (type === BillOfLadingFreightType.Box ? 'es' : 's') : ''
  switch (type) {
    case BillOfLadingFreightType.Bag:
      return `${quantity} Bag${pluralize}`
    case BillOfLadingFreightType.Bale:
      return `${quantity} Bale${pluralize}`
    case BillOfLadingFreightType.Box:
      return `${quantity} Box${pluralize}`
    case BillOfLadingFreightType.Bucket:
      return `${quantity} Bucket${pluralize}`
    case BillOfLadingFreightType.Bundle:
      return `${quantity} Bundle${pluralize}`
    case BillOfLadingFreightType.Can:
      return `${quantity} Can${pluralize}`
    case BillOfLadingFreightType.Carton:
      return `${quantity} Carton${pluralize}`
    case BillOfLadingFreightType.Case:
      return `${quantity} Case${pluralize}`
    case BillOfLadingFreightType.Coil:
      return `${quantity} Coil${pluralize}`
    case BillOfLadingFreightType.Crate:
      return `${quantity} Crate${pluralize}`
    case BillOfLadingFreightType.Cylinder:
      return `${quantity} Cylinder${pluralize}`
    case BillOfLadingFreightType.Drum:
      return `${quantity} Drum${pluralize}`
    case BillOfLadingFreightType.Pail:
      return `${quantity} Pail${pluralize}`
    case BillOfLadingFreightType.Pallet:
      return `${quantity} Pallet${pluralize}`
    case BillOfLadingFreightType.Pieces:
      return `${quantity} Piece${pluralize}`
    case BillOfLadingFreightType.Reel:
      return `${quantity} Reel${pluralize}`
    case BillOfLadingFreightType.Roll:
      return `${quantity} Roll${pluralize}`
    case BillOfLadingFreightType.Skid:
      return `${quantity} Skid${pluralize}`
    case BillOfLadingFreightType.Tote:
      return `${quantity} Tote${pluralize}`
    case BillOfLadingFreightType.Tube:
      return `${quantity} Tube${pluralize}`

    // Deprecated
    case BillOfLadingFreightType.LooseItem:
      return `${quantity} Loose Item${pluralize}`
    case BillOfLadingFreightType.Container:
      return `${quantity} Container${pluralize}`

    default:
      return `${quantity} Item${pluralize}`
  }
}

const _pluralizePieceType = (
  pieceType: BillOfLadingFreightPieceType | null | undefined,
  pieceCount: number | null | undefined,
): string | null | undefined => {
  if (!pieceType || !pieceCount) {
    return pieceType
  }

  const pluralize =
    pieceCount > 1 && pieceType !== BillOfLadingFreightPieceType.Pieces
      ? pieceType === BillOfLadingFreightPieceType.Box
        ? 'es'
        : 's'
      : ''

  return `${pieceType}${pluralize}`
}

/**
 * If the given string is only an integer (i.e. 1234), then
 * return "UN 1234". Else we return the given string.
 */
const _formatUnUaId = (unUaId: string | null | undefined): string => {
  if (!unUaId) {
    return ''
  }

  const trimmedId = unUaId.trim()
  const idAsInt = Number.parseInt(trimmedId)
  const intIdAsString = `${idAsInt}`

  // Not an integer string
  if (trimmedId !== intIdAsString) {
    return trimmedId
  }

  return `UN ${trimmedId}`
}

/**
 * If the hazardous class needs to be displayed in numerical form
 * @param hazardousClass
 * @returns string
 */
const _formatHazardousClass = (hazardousClass: string | null | undefined): string => {
  if (!hazardousClass) {
    return ''
  }

  switch (hazardousClass) {
    case 'Class One':
      return '1'
    case 'Class Two':
      return '2'
    case 'Class Three':
      return '3'
    case 'Class Four':
      return '4'
    case 'Class Five':
      return '5'
    case 'Class Six':
      return '6'
    case 'Class Seven':
      return '7'
    case 'Class Eight':
      return '8'
    case 'Class Nine':
      return '9'
    default:
      return hazardousClass
  }
}

const _formatHazmatDetails = (item: BillOfLadingFreightItem, carrierName: string | undefined): string => {
  let hazmatCountType = ''
  if (!carrierName || !CARRIERS_USING_HAZMAT_PACKAGING_DESCRIPTION.has(carrierName)) {
    hazmatCountType = [item.hazmatPackageCount, item.hazmatPackageType].filter(Boolean).join(' ')
  }

  let hazmatDescription = [
    hazmatCountType,
    _formatUnUaId(item.hazmatUnUaId),
    item.hazmatProperShippingName,
    _formatHazardousClass(item.hazmatHazardClass),
  ]
    .filter(Boolean)
    .join(', ')

  if (item.hazmatPackingGroup && ['I', 'II', 'III'].includes(item.hazmatPackingGroup)) {
    hazmatDescription += `, PG ${item.hazmatPackingGroup}`
  }

  const firstName = item.hazmatEmergencyContactFirstName ?? ''
  const lastName = item.hazmatEmergencyContactLastName ?? ''
  const fullCompanyName = item.hazmatEmergencyContactFullCompanyName
  const phone = item.hazmatEmergencyContactPhoneNumber ?? ''

  // For TForce, we don't want to display the emergency contact name
  let emergencyContact = fullCompanyName ?? carrierName === 'TForce' ? '' : `${firstName} ${lastName}`
  if (phone) {
    emergencyContact += ` - ${formatPhoneNumberToNational(phone)}`
  }

  const emergencyContactDescription = `*** EMERGENCY CONTACT: ${emergencyContact} ***`
  return `${hazmatDescription}\n${emergencyContactDescription}`
}

const _generateDescription = (item: BillOfLadingFreightItem, carrierName: string | undefined): string => {
  if (item.isHazmat) {
    return _formatHazmatDetails(item, carrierName)
  }

  let description = `${item.description} (${item.length}L ${item.width}W ${item.height}H)`
  const pieceCountType = [item.pieceCount, _pluralizePieceType(item.pieceType, item.pieceCount)]
    .filter(Boolean)
    .join(' ')
  if (pieceCountType) {
    description = `${pieceCountType}, ${description}`
  }

  return description
}

const _generateFreightRow = (item: BillOfLadingFreightItem, carrierName: string | undefined): JSX.Element => {
  return (
    <Row width={'100%'} borderTop={BOL_DEFAULT_BORDER} key={item.id}>
      <Text
        borderRight={BOL_DEFAULT_BORDER}
        height={'100%'}
        {...BOL_DEFAULT_HEADER_PADDING}
        width={CARGO_COLUMN_WIDTH[0]}
      >
        {_quantityTypeDescription(item, carrierName)}
      </Text>
      <Text
        borderRight={BOL_DEFAULT_BORDER}
        height={'100%'}
        {...BOL_DEFAULT_HEADER_PADDING}
        width={CARGO_COLUMN_WIDTH[1]}
      >
        {item.isHazmat ? 'X' : ''}
      </Text>
      <Text borderRight={BOL_DEFAULT_BORDER} height={'100%'} {...BOL_DEFAULT_HEADER_PADDING} flex={1}>
        {_generateDescription(item, carrierName)}
      </Text>
      <Text
        borderRight={BOL_DEFAULT_BORDER}
        height={'100%'}
        {...BOL_DEFAULT_HEADER_PADDING}
        width={CARGO_COLUMN_WIDTH[3]}
      >
        {item.freightClass}
      </Text>
      <Text height={'100%'} {...BOL_DEFAULT_HEADER_PADDING} width={CARGO_COLUMN_WIDTH[4]} textAlign="center">
        {item.weight * item.quantity}
      </Text>
    </Row>
  )
}

export const BillOfLadingFreight = (props: BillOfLadingFreightProps): JSX.Element => {
  const { carrierInfo, freight } = props
  const freightTotalWeight = freight.reduce((prev, f) => prev + f.weight * f.quantity, 0)
  const carrierName = carrierInfo?.carrierName

  return (
    <Column width={'100%'} marginTop={BOL_DEFAULT_MARGIN}>
      <Column width={'100%'} border={BOL_DEFAULT_BORDER}>
        {/** Table Header */}
        <Row width={'100%'} backgroundColor={'#eeeeee'}>
          <Text
            bold
            borderRight={BOL_DEFAULT_BORDER}
            height={'100%'}
            {...BOL_DEFAULT_HEADER_PADDING}
            width={CARGO_COLUMN_WIDTH[0]}
          >
            Qty & Uom
          </Text>
          <Text
            bold
            borderRight={BOL_DEFAULT_BORDER}
            height={'100%'}
            {...BOL_DEFAULT_HEADER_PADDING}
            width={CARGO_COLUMN_WIDTH[1]}
          >
            Hazard
          </Text>
          <Column borderRight={BOL_DEFAULT_BORDER} flex={1} {...BOL_DEFAULT_HEADER_PADDING}>
            <Text bold>Description</Text>
            <Text fontSize={6}>ID# UN or NA, Proper Shipping Name, Hazard Class, Packing Group</Text>
          </Column>
          <Text
            bold
            borderRight={BOL_DEFAULT_BORDER}
            height={'100%'}
            {...BOL_DEFAULT_HEADER_PADDING}
            width={CARGO_COLUMN_WIDTH[3]}
          >
            Class
          </Text>
          <Text bold height={'100%'} {...BOL_DEFAULT_HEADER_PADDING} width={CARGO_COLUMN_WIDTH[4]}>
            Weight
          </Text>
        </Row>

        {/** Freight Table Rows */}
        {freight.sort((f) => (f.isHazmat ? -1 : 1)).map((item) => _generateFreightRow(item, carrierName))}
      </Column>

      {/* Total Summary */}
      <Row justifyContent="flex-end" width={'100%'}>
        <Row borderLeft={BOL_DEFAULT_BORDER} borderBottom={BOL_DEFAULT_BORDER} borderRight={BOL_DEFAULT_BORDER}>
          <Text {...BOL_DEFAULT_HEADER_PADDING} bold backgroundColor="#eeeeee" borderRight={BOL_DEFAULT_BORDER}>
            Total Weight (lbs)
          </Text>
          <Text height={'100%'} {...BOL_DEFAULT_HEADER_PADDING} width={CARGO_COLUMN_WIDTH[4]} textAlign="center">
            {freightTotalWeight}
          </Text>
        </Row>
      </Row>
    </Column>
  )
}
